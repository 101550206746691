import { ResetCSS } from 'common/assets/css/style';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import { rideTheme } from 'common/theme/ride';
import Seo from 'components/seo';
import Helmet from 'react-helmet';
import ActivationError from 'containers/Ride/ActivationError';
import { ContentWrapper, GlobalStyle } from 'containers/Ride/ride.style';
import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';

const ActivationPage = () => {

    return (
        <ThemeProvider theme={rideTheme}>
            <Fragment>
                <Seo title="Tus negocios favoritos, en tu mano" />
                <Helmet>
                    <meta name="robots" content="noindex, nofollow" />
                    <script id="cookieyes" type="text/javascript" src="https://cdn-cookieyes.com/client_data/a370565f2585f5f2f12fe533/script.js" />
                </Helmet>
                <ResetCSS />
                <GlobalStyle />
                <ContentWrapper>
                    <ActivationError />
                    {/*<Footer />*/}
                </ContentWrapper>
            </Fragment>
        </ThemeProvider>
    );
};
export default ActivationPage;
